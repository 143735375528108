import config, { getIsMaintenanceActive } from "~/config";
import useAdminCustomerData from "~/composables/useAdminCustomerData";

export default defineNuxtRouteMiddleware(async (to) => {
  if (getIsMaintenanceActive()) {
    return navigateTo(config.maintenancePage);
  }

  if (process.client) {
    // The asyncUserData flag is used to determine if the middleware should fetch data asynchronously
    // This is used for the entry point of the customer area, so that the page can be rendered without waiting for the data
    const isFetchingAsync = (to.meta?.meta as any)?.asyncUserData;
    const adminImpStore = useAdminImpersonificationStore();
    const { getCustomerById, getContractsForCustomer, getRecommendations, getAllLabels } =
      useAdminCustomerData();

    const { params } = to;
    const targetKey = params.customerId;
    const customerId = Array.isArray(targetKey) ? targetKey[0] : targetKey;

    const promises: Promise<any>[] = [];

    if (adminImpStore.customer && customerId !== adminImpStore.customer.id) {
      // If user was switched right before navigation, stop impersonification so
      // that the new data will be fetched
      adminImpStore.stopImpersonification();
    }

    if (!adminImpStore.customer) {
      // Only load the customer data synchronously
      await getCustomerById(customerId);
    }

    // Load the rest of the data asynchronously in the background
    if (!adminImpStore.contracts) {
      promises.push(getContractsForCustomer(customerId));
    }

    if (!adminImpStore.labels) {
      promises.push(getAllLabels());
    }

    if (!adminImpStore.recommendations) {
      promises.push(getRecommendations(customerId));
    }

    if (promises.length) {
      if (isFetchingAsync) {
        // Load and save customer related data asynchronously in the background
        Promise.all(promises).then(() => {});
      } else {
        await Promise.all(promises).then(() => {});
      }
    }
  }
});
